import $ from "jquery";
import "what-input";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";
gsap.registerPlugin(
  ScrollTrigger,
  ScrollToPlugin,
  SplitText,
  CSSRulePlugin,
  ScrollSmoother
);

// import Swiper bundle with all modules installed
// import Swiper from "swiper/bundle";

// const swiper = new Swiper(...);

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

window.jQuery = $;
require("foundation-sites");

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
$(document).foundation();

const APP = {
  init: function () {
    console.log("APP");

    APP.bindButtonEvents();
    APP.protectEmails();
    // APP.swiperInit();
    $(window).on("scroll", APP.onScrollEvents);

    $("video").on("replaced.zf.interchange", function (e) {
      console.log('vrp');
      let video = $(e.target)[0].play();
    });
  },

  bindButtonEvents: function () {
    $("a.scrollto").on("click", function (event) {
      event.preventDefault();
      console.log($(this).attr("href"));
      gsap.to(window, {
        duration: 1.5,
        scrollTo: {
          y: $(this).attr("href"),
          offsetY: $(".sticky-container").height(),
        },
        ease: "power2.out",
      });
    });

    $(".button.consent").on("click", function (event) {
      event.preventDefault();
      const iframe = $(this).closest(".reveal").find("iframe");
      iframe.attr("src", iframe.attr("data-src"));

      $(this).remove();
    });
  },

  protectEmails: function () {
    let emailLinks = $("a.liam");
    emailLinks.on("click", function () {
      var href = $(this).attr("href");
      $(this).attr("href", href.replace("loveletters.", ""));
    });
  },

  onScrollEvents: function () {
    let scroll = $(window).scrollTop();
    if (scroll > 0 && $("body").is(':not(".is-scrolled")')) {
      $("body").addClass("is-scrolled");
      $("body").trigger("isScrolled");
    } else if (scroll === 0 && $("body").is(".is-scrolled")) {
      $("body").removeClass("is-scrolled");
      $("body").trigger("isNotScrolled");
    }
  },

  // swiperInit: function () {
  //   const swiper = new Swiper(".swiper", {
  //     // Optional parameters
  //     loop: true,

  //     navigation: {
  //       nextEl: ".swiper-button-next",
  //       prevEl: ".swiper-button-prev",
  //     },
  //   });
  // },
};

const GSAP = {
  init: function () {
    console.log("GSAP.init");

    GSAP.menuBehavior();
    GSAP.buttonsHover();
    GSAP.videosPlayStopScroll();
    GSAP.sectionAnimationScroll();
    GSAP.uiSmallAimations();

    // if ($("#gmbhIntroVideo").length) {
    // }
  },

  windowLoaded: function () {
    if ($("#history").length) {
      GSAP.timelineAnimation();
    }

    if ($("#home").length) {
      GSAP.welcomeAnimation();
    }

    if ($("#gmbhIntro").length) {
      GSAP.gmbhIntroScroller();
    }
  },

  menuBehavior: function () {
    const $burgerButton = $(".button.burger");
    const $icon = $burgerButton.find(".icon");
    // Setup Timeline
    const menuTL = gsap.timeline({
      paused: true,
      onStart: function () {
        $("#mainMenu").css("visibility", "visible");
      },
      onComplete: function () {
        console.log("onComplete");
        $("#mainMenu")
          .removeClass("is-closed")
          .addClass("is-open")
          .css("visibility", "visible");

        $(window).one("scroll", function () {
          $icon.removeClass("step1 step2 rev-step2").addClass("rev-step1");
          menuTL.reverse();
        });

        $(".off-canvas-content").one("click", function () {
          $icon.removeClass("step1 step2 rev-step2").addClass("rev-step1");
          menuTL.reverse();
        });
      },
      onReverseComplete: function () {
        console.log("onReverseComplete");
        $("#mainMenu")
          .removeClass("is-open")
          .addClass("is-closed")
          .css("visibility", "hidden");
      },
    });

    // Define Animations
    menuTL.fromTo(
      "#mainMenu",
      { x: "100%" },
      { duration: 0.5, x: "0%", ease: "power2.out" },
      0
    );
    menuTL.to(
      ".title-bar-right button span.btnlabel",
      { duration: 0.4, opacity: 0, ease: "power2.out" },
      0
    );
    menuTL.fromTo(
      "#mainNav > ul > li",
      { opacity: 0, x: 100 },
      { duration: 0.3, opacity: 1, x: 0, stagger: 0.1 },
      0.2
    );

    $burgerButton
      .on("mouseover focus", function () {
        $icon.removeClass("rev-step1").addClass("step1");
      })
      .on("mouseleave blur", function () {
        if (!$icon.is(".rev-step2"))
          $icon.removeClass("step1 step2 rev-step2").addClass("rev-step1");
      });

    $burgerButton.on("click", function () {
      if ($("#mainMenu").is(".is-closed")) {
        $icon.removeClass("step2").addClass("rev-step2");
        menuTL.play();
      } else {
        $icon.removeClass("rev-step2").addClass("step1");
        menuTL.reverse();
      }
    });
  },

  buttonsHover: function () {
    const buttons = gsap.utils.toArray(".button");
    buttons.forEach((el) => {
      let arrow = $(el).find(".arrow-right");

      if (arrow.length) {
        let dur = 0.25;
        $(el).css({ overflow: "hidden" });
        if ($(el).find(".text").length == 0) {
          $(el).contents().eq(0).wrap('<span class="text"/>');
        }
        let text = $(el).find(".text");

        $(
          '<span class="icon duplicate" style="margin:0 0 0 -50px;">' +
            arrow.html() +
            "</span>"
        ).insertBefore(text);
        let dup = $(el).find(".duplicate");

        let buttonTL = gsap.timeline({ paused: true });
        buttonTL.to(arrow, { x: "+=50", duration: dur }, 0);
        buttonTL.to(text, { x: "+=55", duration: dur }, 0.05);
        buttonTL.to(dup, { x: "+=46", duration: dur }, 0.1);

        $(el)
          .on("mouseenter", function () {
            buttonTL.play();
          })
          .on("mouseleave", function () {
            buttonTL.reverse();
          });
      }
    });

    const branchAccTitles = gsap.utils.toArray(
      ".branchaccordion .accordion-title"
    );
    branchAccTitles.forEach((el) => {
      let icon = $(el).find(".icon");
      let arrowTL = gsap.timeline({ paused: true });

      let mainArrow = icon.find("#mainArrow");
      let topArrow = icon.find("#topArrow");
      let close = icon.find("#close");

      let dur = 0.25;

      gsap.set(close, { yPercent: -320, x: 1 });

      arrowTL.addLabel("start");
      arrowTL.to(topArrow, { yPercent: 140, duration: dur }, 0);
      arrowTL.to(mainArrow, { yPercent: 140, duration: dur }, 0);
      arrowTL.addLabel("click");
      arrowTL.addPause("click");
      arrowTL.to(topArrow, { yPercent: 280, duration: dur }, "click");
      arrowTL.to(close, { yPercent: -155, duration: dur }, "click");
      arrowTL.addLabel("open");

      $(el)
        .on("mouseenter", function () {
          if (!$(el).parent().is(".is-active")) arrowTL.play("start");
        })
        .on("mouseleave", function () {
          if (!$(el).parent().is(".is-active")) arrowTL.reverse("click");
        })
        .on("click", function () {
          if ($(el).parent().is(".is-active")) arrowTL.play("click");
          else arrowTL.reverse("open");
        });
    });
  },

  gmbhIntroScroller: function () {
    // const video = $("#gmbhIntroVideo")[0];
    const video = document.querySelector(".video-sketchscrub");
    const windowHeight = window.innerHeight;
    const videoHeight = video.offsetHeight;
    let src = video.currentSrc || video.src;
    console.log(video, src);

    /* Make sure the video is 'activated' on iOS */
    function once(el, event, fn, opts) {
      var onceFn = function (e) {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
      };
      el.addEventListener(event, onceFn, opts);
      return onceFn;
    }

    once(document.documentElement, "touchstart", function (e) {
      video.play();
      video.pause();
    });

    let tl = gsap.timeline({
      defaults: { duration: 1 },
      scrollTrigger: {
        trigger: "#videoWrapper",
        start: "top 75%",
        end: "+=1300px",
        scrub: true,
        //pin: true,
        // markers: true,
      },
    });

    ScrollTrigger.create({
      trigger: "#videoWrapper",
      start: "top " + (windowHeight - videoHeight) / 2 + "px",
      end: "+=400px",
      //scrub: true,
      pin: true,
      // markers: true,
    });

    once(video, "loadedmetadata", () => {
      tl.fromTo(
        video,
        {
          yPercent: 10,
          currentTime: 0,
        },
        {
          yPercent: 0,
          currentTime: video.duration || 1,
          ease: "none",
        }
      );
    });

    /* When first coded, the Blobbing was important to ensure the browser wasn't dropping previously played segments, but it doesn't seem to be a problem now. Possibly based on memory availability? */
    setTimeout(function () {
      if (window["fetch"]) {
        fetch(src)
          .then((response) => response.blob())
          .then((response) => {
            var blobURL = URL.createObjectURL(response);

            var t = video.currentTime;
            once(document.documentElement, "touchstart", function (e) {
              video.play();
              video.pause();
            });

            video.setAttribute("src", blobURL);
            video.currentTime = t + 0.01;
          });
      }
    }, 1000);

    /* ---------------------------------- */
  },

  videosPlayStopScroll: function () {
    const videos = gsap.utils.toArray(".video--scrollcontroll");
    videos.forEach((videoel) => {
      // if ( $('.timeline-imagevid').length ) {

      let video = videoel.find("video")[0];
      let overlay = videoel.find(".vid-overlay");

      video.currentTime = 1;
      video.pause();

      // setup Timeline
      const overlayTL = gsap.fromTo(
        overlay,
        { opacity: 0.5 },
        {
          duration: 0.5,
          opacity: 0,
          onStart: () => video.play(),
          onReverseComplete: () => video.pause(),
          ease: "power2.out",
        }
      );

      // setup Scrolltrigger
      ScrollTrigger.create({
        trigger: videoel,
        start: "top 70%",
        end: "bottom 30%",
        markers: false,
        animation: overlayTL,
        onEnter: () => {
          overlayTL.play();
        },
        onEnterBack: () => {
          overlayTL.play();
        },
        onLeave: () => {
          overlayTL.reverse();
        },
        onLeaveBack: () => {
          overlayTL.reverse();
        },
      });
      // }
    });
  },

  sectionAnimationScroll: function () {
    const contentSections = gsap.utils.toArray(".flow-in");
    contentSections.forEach((section) => {
      const inups = gsap.utils.toArray($(section).find(".inup"));
      inups.forEach((inup) => {
        gsap.from(inup, {
          // this will animate ALL boxes
          y: 100,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            markers: false,
            trigger: inup, // this will use the first box as the trigger
            scrub: false,
            start: "top 90%",
            toggleActions: "play none none reverse",
          },
        });
      });

      const fixedImg = gsap.utils.toArray($(section).filter(".stickyimage"));
      fixedImg.forEach((fixedImg, index) => {
        let media = $(fixedImg).find(".bg-video");
        media
          .find("img, video")
          .clone()
          .attr("class", "eviltwin")
          .appendTo(media);
        let twin = media.find(".eviltwin");

        $(fixedImg).css({
          "z-index": "5",
          overflow: "clip",
        });
        media
          .css({ "background-color": "rgba(0,0,0,0)" })
          .find("img, video")
          .not(".eviltwin")
          .css({
            opacity: "0",
            visibility: "hidden",
          });
        twin.css({
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          "z-index": "-1",
          "object-fit": "cover",
          opacity: 0.4,
          // display: "none",
          "max-height": "100vh",
        });

        // const mediaTL = gsap.timeline({ paused: true });
        // mediaTL.fromTo(
        //   twin,
        //   { yPercent: index == 0 ? 0 : 50 },
        //   { yPercent: "-50", duration: 1, ease: "none" }
        // );

        // ScrollTrigger.create({
        //   trigger: media,
        //   scrub: true,
        //   animation: mediaTL,
        //   // onEnter: () => {
        //   //   gsap.set(twin, { display: "block" });
        //   // },
        //   // onEnterBack: () => {
        //   //   gsap.set(twin, { display: "block" });
        //   // },
        //   // onLeave: () => {
        //   //   gsap.set(twin, { display: "none" });
        //   // },
        //   // onLeaveBack: () => {
        //   //   gsap.set(twin, { display: "none" });
        //   // },
        // });
      });
    }); // contentsection each
  },

  uiSmallAimations: function () {
    // scrollhint arrow
    const scrollhint = $(".scrollhint");
    const scrollhintTL = gsap.timeline();
    scrollhintTL.to(scrollhint, {
      duration: 2,
      opacity: 1,
      yPercent: 20,
      delay: 8,
      repeat: -1,
      yoyo: true,
      ease: "power2.inout",
    });

    // open Imagevideo
    const herovideOverlay = $("#heroFrame .bg-video");
    const herovideo = $("#heroFrame .bg-video video");
    // herovideOverlay.append('');
    const playImageVideoTL = gsap.timeline({ paused: true });

    playImageVideoTL.set(" .scrollhint", { dispaly: "none" });

    playImageVideoTL.fromTo(
      herovideo,
      { opacity: 0.4 },
      { opacity: 1, duration: 0.5, ease: "power2.inout" },
      0
    );
    playImageVideoTL.to(
      ".hero-content .cell",
      { opacity: 0, duration: 0.5, ease: "power2.inout" },
      0
    );
    playImageVideoTL.to(
      ".hero-content",
      { "--bg-opacity": 0, duration: 0.5, ease: "power2.inout" },
      0
    );

    playImageVideoTL.to(".openvideo .text", { opacity: 1, duration: 0.3 }, 0.3);

    playImageVideoTL.set(" .scrollhint", { dispaly: "block" });

    $(".openvideo")
      .on("mouseenter", function () {
        playImageVideoTL.play();
      })
      .on("mouseleave", function () {
        playImageVideoTL.reverse();
      });

    // fadein topbar background
    const topbarBG = $(".title-bar-bg");
    const topbarTL = gsap.timeline({ paused: true });
    topbarTL.to(topbarBG, { duration: 0.4, opacity: 1, ease: "power2.out" });

    $("body")
      .on("isScrolled", function () {
        if ($("#home").length) {
          topbarTL.play();
          scrollhintTL.pause();
          gsap.to(scrollhint, { opacity: 0 });
        }
      })
      .on("isNotScrolled", function () {
        if ($("#home").length) topbarTL.reverse();
      });

    const bigbuttons = gsap.utils.toArray(".bigbutton-ani");
    bigbuttons.forEach((button) => {
      const bigButtonTL = gsap.timeline({
        paused: true,
      });
      let basecolor = $(button).closest("section").is(".text-white")
        ? "rgb(255, 255, 255)"
        : "rgb(0, 25, 67)";

      bigButtonTL.to(button, { "--stroke": "0.01em", duration: 0.4 }, 0);
      bigButtonTL.fromTo(
        button,
        {
          "--bb-gradient":
            "linear-gradient(90deg, " +
            basecolor +
            " -15%,rgb(18, 113, 255) -15%, rgb(18, 113, 255) -10%, " +
            basecolor +
            " 0)",
        },
        {
          "--bb-gradient":
            "linear-gradient(90deg, " +
            basecolor +
            " -15%,rgb(18, 113, 255) -15%, rgb(18, 113, 255) 110%, " +
            basecolor +
            " 120%)",
          duration: 1,
        },
        0
      );
      bigButtonTL.addPause();
      bigButtonTL.addLabel("center");
      bigButtonTL.to(
        button,
        {
          "--bb-gradient":
            "linear-gradient(90deg, " +
            basecolor +
            " 100%,rgb(18, 113, 255) 190%, rgb(18, 113, 255) 200%, " +
            basecolor +
            " 200%)",
          duration: 5,
        },
        "center +=0.3"
      );
      bigButtonTL.to(
        button,
        { "--stroke": "0em", duration: 0.4 },
        "center +=0.3"
      );

      $(button)
        .on("mouseenter", function () {
          bigButtonTL.play(0);
        })
        .on("mouseleave", function () {
          bigButtonTL.play("center");
        });
    });
  },

  timelineAnimation: function () {
    const vidSection = $(".timeline-imagevid");
    const video = vidSection.find("video");

    video.appendTo($("#history"));
    vidSection.css({ overflow: "hidden" });
    video.css({
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      "z-index": "0",
      "object-fit": "cover",
      // 'display':'none'
    });

    let tlTL;

    gsap.set(".timelineelement .bluebar, .timelineelement .section-line", {
      height: 0,
    });

    gsap.set(".timelineelement .inup, .timelineelement .time", {
      y: 50,
      opacity: 0,
    });

    ScrollTrigger.batch(".timelineelement", {
      // interval: 0.25,
      // batchMax: 100,
      once: true,
      start: "top 55%",
      onEnter: (batch) => {
        if (!tlTL || !tlTL.isActive()) {
          tlTL = gsap.timeline();
        }

        let end = tlTL.duration();
        batch.forEach((section, index) => {
          const bars = $(section).find(".bluebar");
          const lines = $(section).find(".section-line");
          const times = $(section).find(".time");
          const texts = $(section).find(".inup");
          tlTL.to(
            lines,
            { opacity: 1, y: 0, height: "100%", duration: 0.4 },
            end + index * 0.35
          );
          tlTL.to(
            bars,
            { opacity: 1, y: 0, height: "100%", duration: 0.4 },
            end + index * 0.4
          );
          tlTL.to(
            times,
            { opacity: 1, y: 0, height: "100%", duration: 0.4 },
            end + index * 0.6
          );
          tlTL.to(
            texts,
            { opacity: 1, y: 0, height: "100%", duration: 0.4 },
            end + index * 0.7
          );
        });
      },
    });

    const section = gsap.utils.toArray(".timelineelement");
    section.forEach((section) => {
      const overline = $(section).find(".timestamp");
      const scaleTL = gsap.timeline({ paused: true });

      gsap.set(overline, { textShadow: "0px 0px 0px rgba(0,0,0,0.0)" });

      scaleTL.to(overline, {
        scale: 1.18,
        textShadow: "0px 0px 5px rgba(0,0,0,0.6)",
        duration: 0.3,
        ease: "none",
        onComplete: () => {
          $(section).addClass("active");
        },
        onReverseComplete: () => {
          $(section).removeClass("active");
        },
      });

      const st = ScrollTrigger.create({
        trigger: section,
        start: "top 55%",
        end: "bottom 55%",
        animation: scaleTL,
        toggleActions: "play reverse play reverse",
      });
    });

    $("#imageSwitch").on("change", function () {
      if ($(this).is(":checked")) {
        gsap.fromTo(
          $("#history .inlineimage"),
          { height: 0 },
          {
            height: "100%",
            duration: 0.3,
            onComplete: () => {
              ScrollTrigger.refresh();
              gsap.to(window, {
                duration: 0.5,
                delay: 0.6,
                scrollTo: {
                  y: $("#historySections .active"),
                  offsetY: $(window).height() * 0.4,
                },
              });
            },
          }
        );
      } else {
        gsap.to($("#history .inlineimage"), {
          height: 0,
          duration: 0.3,
          onComplete: () => {
            ScrollTrigger.refresh();
            gsap.to(window, {
              duration: 0.5,
              delay: 0.6,
              scrollTo: {
                y: $("#historySections .active"),
                offsetY: $(window).height() * 0.4,
              },
            });
          },
        });
      }

      ScrollTrigger.refresh();
      gsap.to(window, {
        duration: 0.5,
        delay: 0.5,
        scrollTo: {
          y: $("#historySections .active"),
          offsetY: $(window).height() * 0.4,
        },
      });
    });
  },

  welcomeAnimation: function () {
    const heroSection = $("#home .hero-content h1, #home .hero-content p");
    const menuBar = $("#titlebarWrapper");
    const welcomeTL = gsap.timeline({});
    welcomeTL.set(menuBar, { display: "block" });
    welcomeTL.from("#heroFrame video", {
      opacity: 0,
      duration: 1,
      ease: "power2.out",
    });
    // welcomeTL.fromTo(
    //   heroSection,
    //   { opacity: 0, y: 100 },
    //   { opacity: 1, y: 0, duration: 1, stagger: 0.3, ease: "power2.out" },
    //   0
    // );
    welcomeTL.to(menuBar, { y: 0, duration: 1, ease: "power2.out" }, 0.5);

    gsap.fromTo(
      "#heroFrame video",
      { opacity: 0.5 },
      {
        opacity: 0.1,
        duration: 0.6,
        scrollTrigger: {
          trigger: "#heroFrame",
          start: "bottom center",
          end: "bottom top",
          toggleActions: "play none none reverse",
          onEnter: () => {
            // $('#heroFrame .bg-video')
            $("#heroFrame video")[0].pause();
          },
          onLeaveBack: () => {
            $("#heroFrame video")[0].play();
          },
        },
      }
    );
  },
};

$(document).ready(() => {
  APP.init();
  GSAP.init();
});

// DEV MODE
$(window).on("load", GSAP.windowLoaded());

const initialGradient = "linear-gradient(blue 0 100%) bottom / 0 no-repeat";

let anchors = gsap.utils.toArray("a.ani--push");
anchors.forEach((el) => {
  $(el).css({
    "background-clip": "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "-webkit-background-clip": "text",
    "background-image":
      "linear-gradient(0deg, #ffffff, #ffffff 50%, #1271FF 0)",
    "background-size": "100% 200%",
    "background-position": "100% 100%",
  });

  let arrow = $(el).find(".arrow-down");
  let texts = $(el).find("h3");

  // if ( arrow.length ) {
  var dur = 0.25;
  // $(el).css({'overflow':'hidden'});gulp
  // $(el).contents().eq(0).wrap('<span class="text"/>');
  // let text = $(el).find('.text');

  // $( '<span class="icon duplicate" style="margin:0 0 0 -50px;">' + arrow.html() + '</span>' ).insertBefore(text);
  // let dup = $(el).find('.duplicate');

  let anchorsTL = gsap.timeline({ paused: true });
  // anchorsTL.to(arrow,{x: '+=50',duration: dur},0);
  anchorsTL.to(el, {
    backgroundPosition: "100% 0%", // Animate from bottom to top
    color: "#ffffff",
    duration: dur, // Animation duration in seconds
    ease: "linear",
  });
  // anchorsTL.to(texts,{
  //   x: '+=55',
  //   backgroundPosition: "100% 100%", // Animate from bottom to top
  //   color: "#1271FF",
  //   duration: dur},0.05);
  // anchorsTL.to(dup,{x: '+=46',duration: dur},0.1);

  $(el)
    .on("mouseenter", function () {
      anchorsTL.play();
    })
    .on("mouseleave", function () {
      anchorsTL.reverse();
    });
  // }
});
